import dynamic from "next/dynamic";
import { renderLineBreaks } from "../../../utils";
import {
  PersonCollectionWrapper,
  ProfilesWrapper,
  ProfileDivision,
  Profile,
  ProfileImageWrapper,
  ProfileImage,
  BorderImage,
  ProfileName,
  ProfileRole,
  Testimony,
  OnePersonWrapper,
  OnePersonDataWrapper,
  OnePersonData,
  OnePersonName,
  OnePersonRole,
  OnePersonAudioWrapper,
  OnePersonMobileImage,
  OnePersonTestimony,
  OnePersonDesktopImage,
  ScrollBar,
  EditorWrapper,
} from "./styles";
import { useState } from "react";
import EditorJSContent from "../../editor-js-content";

const AudioItem = dynamic(() => import("../audio-item/audio-item"));

const PersonCollection = ({ collection }) => {
  const [indexPersonSelected, setIndexPersonSelected] = useState(0);

  return (
    <PersonCollectionWrapper>
      <ProfilesWrapper>
        {collection.map(({ profileImage, name, role }, index) => (
          <ProfileDivision
            key={index}
            onClick={() => setIndexPersonSelected(index)}
          >
            <Profile $selected={index === indexPersonSelected}>
              {profileImage && (
                <ProfileImageWrapper>
                  {index === indexPersonSelected && <BorderImage />}
                  <ProfileImage media={profileImage} />
                </ProfileImageWrapper>
              )}
              {name && <ProfileName>{name}</ProfileName>}
              {role && <ProfileRole>{role}</ProfileRole>}
            </Profile>
          </ProfileDivision>
        ))}
      </ProfilesWrapper>
      {collection[indexPersonSelected].audio && (
        <AudioItem media={collection[indexPersonSelected].audio} />
      )}
      {collection[indexPersonSelected].testimony && (
        <ScrollBar>
          <Testimony>
            <EditorJSContent
              content={collection[indexPersonSelected].testimony}
            />
          </Testimony>
        </ScrollBar>
      )}
    </PersonCollectionWrapper>
  );
};

export const PersonDetail = ({
  profileImage,
  name,
  role,
  companyTime,
  testimony,
  audio,
  collection,
}) => {
  if (collection) return <PersonCollection collection={collection} />;

  return (
    <OnePersonWrapper>
      <OnePersonDataWrapper>
        <OnePersonData>
          {name && <OnePersonName>{name}</OnePersonName>}
          {role && <OnePersonRole>{role}</OnePersonRole>}
          {companyTime && <OnePersonRole>{companyTime}</OnePersonRole>}
        </OnePersonData>
        {profileImage && <OnePersonDesktopImage media={profileImage} />}
      </OnePersonDataWrapper>
      <OnePersonAudioWrapper>
        {audio && <AudioItem media={audio} title={name} subtitle={role} />}
        {profileImage && <OnePersonMobileImage media={profileImage} />}
      </OnePersonAudioWrapper>
      {testimony && (
        <OnePersonTestimony>
          <EditorWrapper>
            <EditorJSContent content={testimony} />
          </EditorWrapper>
        </OnePersonTestimony>
      )}
    </OnePersonWrapper>
  );
};

export default PersonDetail;
