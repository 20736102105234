import tw from "tailwind-styled-components";
import styled from "styled-components";
import NextImage from "../next-image/next-image";

export const PersonCollectionWrapper = tw.div`
  flex
  flex-col
  bg-gray-100
  rounded-2xl
  gap-6
  p-6
  pt-4
  h-full

  desktop:max-h-[460px]
`;

export const ProfilesWrapper = tw.div`
  flex
  flex-row
  border-gray-200
  border-b
`;

export const ProfileDivision = tw.div`
  flex
  justify-center
  w-1/3
  cursor-pointer
`;

export const Profile = tw.div`
  flex
  flex-col
  items-center
  pt-2
  pb-6
  px-1
  
  ${({ $selected }) => $selected && `border-b-4 border-primary-800`}

  desktop:pb-12
`;

export const ProfileImageWrapper = tw.div`
  flex
  justify-center
  items-center
  mb-4
`;

export const ProfileImage = tw(NextImage)`
  h-16
  w-16
  object-cover
  rounded-full
  z-[5]
  border-solid
  border-2
  border-white
`;

export const BorderImage = tw.span`
  absolute
  bg-primary-800
  rounded-full
  w-[72px]
  h-[72px]
`;

export const ProfileName = tw.p`
  font-bold
  text-base
`;

export const ProfileRole = tw.p`
  text-base
`;

export const Testimony = styled.div`
  font-size: 16px;
  line-height: 24px;

  p {
    margin: 0;
  }
`;

export const ScrollBar = styled.div`
  padding-right: 4px;
  overflow-y: auto;
  overflow-x: hidden;

  scrollbar-width: thin;
  scrollbar-color: rgba(204, 204, 204, 1) rgba(204, 204, 204, 0.3);

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(204, 204, 204, 1);
  }

  &::-webkit-scrollbar-track {
    background: rgba(204, 204, 204, 0.3);
  }
`;

export const OnePersonWrapper = tw.div`
  flex
  flex-col
  bg-gray-100
  rounded-2xl
  gap-2
  px-6
  py-2

  desktop:p-6
  desktop:pb-4
`;

export const OnePersonDataWrapper = tw.div`
  hidden
  justify-between
  border-b
  border-gray-200
  pb-6

  desktop:flex
  desktop:flex-row
`;

export const OnePersonData = tw.div`
  flex
  flex-col
  items-center
  justify-center
  grow
`;

export const OnePersonName = tw.p`
  text-xl
`;

export const OnePersonRole = tw.p`
  text-base
`;

export const OnePersonAudioWrapper = tw.div`
  flex
  flex-row
  justify-between
  items-center
  gap-6

  desktop:pt-4
`;

export const OnePersonMobileImage = tw(NextImage)`
  object-cover
  w-16
  h-16
  rounded-full
  border-solid
  border-2
  border-white

  desktop:hidden
`;

export const OnePersonTestimony = tw.div`
  text-base
  border-t
  border-gray-200
  pt-2
  pb-1

  desktop:pt-2
  desktop:pb-0
  desktop:border-0
`;

export const OnePersonDesktopImage = tw(NextImage)`
  object-cover
  w-28
  h-28
  rounded-full
  border-solid
  border-2
  border-white
`;

export const EditorWrapper = styled.div`
  p {
    margin: 0;
  }
`;
